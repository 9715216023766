import { useState, useEffect } from "react";

import { dataset } from "./dataset";

const getFieldNames = () => {
  /*
    What other filters are there above:
     - period type period_type = month
     - date report_dt = last available
     + product group dash_product = if there are several then random
     + product product_offer = all
     + channel channel = all
     + sales method presentation_system = all

     - number of periods
     - split parameters
  */
  return [
    { ru: "TB", en: "tb", checked: false },
    { ru: "GOSB", en: "gosb", checked: false },
    { ru: "Region", en: "region", checked: false },
    { ru: "Sale channel", en: "channel", checked: true },
    { ru: "Channel type", en: "channel_type", checked: true },
    { ru: "Sale role", en: "salling_role", checked: false },
    { ru: "Presentation system", en: "presentation_system", checked: true },
    { ru: "Product group", en: "dash_product", checked: false },
    { ru: "Product offer", en: "product_offer", checked: false },
  ];
};

const getUniqueMetrics = () => {
  const uniqueMetrics = new Set();
  for (let i = 0; i < dataset.length; i++) {
    uniqueMetrics.add(dataset[i].metric);
  }
  return Array.from(uniqueMetrics).map((metric) => ({
    ru: metric,
    checked: true,
  }));
};

const getUniqueValuesByFieldName = (fieldName) => {
  const uniqueValues = new Set();
  for (let i = 0; i < dataset.length; i++) {
    uniqueValues.add(dataset[i][fieldName]);
  }
  return Array.from(uniqueValues).map((item) => [
    { fieldName: fieldName, fieldValue: item },
  ]);
};

const getUniqueValuesForFieldByFieldNamesAndFieldValues = (
  fieldName,
  arrayOfFieldNamesAndFieldsValues
) => {
  // например ('region', [{fieldName: 'tb',fieldValue: 'ТБ 1'}, {fieldName: 'gosb',fieldValue: 'ГОСБ 1'}])
  let uniqueValues = new Set();

  for (let i = 0; i < dataset.length; i++) {
    const item = dataset[i];
    const itemMatchParams = arrayOfFieldNamesAndFieldsValues.reduce(
      (matching, condition) => {
        return item[condition.fieldName] === condition.fieldValue && matching;
      },
      true
    );

    if (itemMatchParams) {
      uniqueValues.add(item[fieldName]);
    }
  }

  const result = Array.from(uniqueValues).map((item) => {
    return [
      ...arrayOfFieldNamesAndFieldsValues,
      { fieldName: fieldName, fieldValue: item },
    ];
  });
  return result;
};

const getRowHeadersByFields = (arrayOfFieldNames) => {
  const result = getUniqueValuesByFieldName(arrayOfFieldNames[0]);

  for (let i = 1; i < arrayOfFieldNames.length; i++) {
    for (let j = 0; j < result.length; j++) {
      if (result[j].length === i) {
        let values = getUniqueValuesForFieldByFieldNamesAndFieldValues(
          arrayOfFieldNames[i],
          result[j]
        );
        result.splice(j + 1, 0, ...values);
        j += values.length;
      }
    }
  }

  return result;
};

const getSumForMetricByFieldsAndFieldsValues = (
  metricName,
  arrayOfFieldsAndFieldsValues
) => {
  // например ('Sale count', [{field: 'tb',fieldValue: 'ТБ 1'}, {field: 'gosb',fieldValue: 'ГОСБ 1'}])
  const result = dataset.reduce((sum, item) => {
    if (item.metric === metricName) {
      const filedsMatch = arrayOfFieldsAndFieldsValues.reduce(
        (matching, condition) => {
          return item[condition.fieldName] === condition.fieldValue && matching;
        },
        true
      );

      if (filedsMatch) {
        return metricName === "Conversion"
          ? (sum = (sum + item.value) / 2)
          : (sum += item.value);
      }
    }
    return sum;
  }, 0);
  return metricName === "Conversion" ? result.toFixed(2) + "%" : result;
};

const getDataByMetricsAndRowHeaders = (metrics, rowHeaders) => {
  const data = {
    metrics: {
      name: "Fields",
      cells: [],
    },
    rows: [],
    sums: {
      name: "Total",
      cells: [],
    },
  };

  data.metrics.cells = metrics.map((metric) => metric.ru);

  for (let i = 0; i < rowHeaders.length; i++) {
    data.rows.push({
      rowHeader: rowHeaders[i],
      margin: rowHeaders[i].length,
      cells: metrics.map((metric) => {
        return getSumForMetricByFieldsAndFieldsValues(metric.ru, rowHeaders[i]);
      }),
    });
  }

  data.sums.cells = metrics.map((metric) =>
    getSumForMetricByFieldsAndFieldsValues(metric.ru, [])
  );

  return data;
};

const PivotTable = ({ data, selectedFields }) => {
  const initSettings = {};
  const [openSettings, setOpenSettings] = useState(initSettings);

  const tableStyle = {
    borderCollapse: "collapse",
    width: "800px",
    margin: "10px auto",
  };

  const t = {
    border: "1px solid gray",
  };

  const h = {
    border: "1px solid gray",
    fontWeight: "bold",
  };

  const makeKeyFromHeader = (rowHeader) => {
    return rowHeader.map((e) => e.fieldValue).join("/");
  };

  const switchFolderOpened = (key) => {
    const settings = { ...openSettings };
    settings[key] = !openSettings[key];
    setOpenSettings(settings);
  };

  useEffect(() => {
    if (data.rows.length) {
      const settings = {};
      for (let i = 0; i < data.rows.length; i++) {
        if (data.rows[i].rowHeader.length < selectedFields.length) {
          settings[makeKeyFromHeader(data.rows[i].rowHeader)] = false;
        }
      }
      setOpenSettings(settings);
    }
  }, [data, selectedFields]);

  return (
    <table style={tableStyle}>
      <thead>
        <tr>
          <td style={h}>{data.metrics.name}</td>
          {data.metrics.cells.map((cell, i) => (
            <td key={i} style={h}>
              {cell}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row, i) => {
          let isRowShowing = true;
          if (row.rowHeader.length > 1) {
            for (let i = 0; i < row.rowHeader.length - 1; i++) {
              isRowShowing =
                isRowShowing &&
                openSettings[makeKeyFromHeader(row.rowHeader.slice(0, i + 1))];
            }
          } else {
            isRowShowing = true;
          }
          if (isRowShowing) {
            return (
              <tr key={i}>
                <td
                  style={{
                    border: "1px solid gray",
                    paddingLeft: row.margin * 20 + "px",
                  }}
                >
                  {row.rowHeader.length < selectedFields.length && (
                    <button
                      onClick={() =>
                        switchFolderOpened(makeKeyFromHeader(row.rowHeader))
                      }
                    >
                      {openSettings[makeKeyFromHeader(row.rowHeader)]
                        ? "-"
                        : "+"}
                    </button>
                  )}
                  {row.rowHeader[row.rowHeader.length - 1].fieldValue}
                </td>
                {row.cells.map((cell, i) => (
                  <td key={i} style={t}>
                    {cell}
                  </td>
                ))}
              </tr>
            );
          }
          return null;
        })}
      </tbody>
      <tfoot>
        <tr>
          <td style={h}>{data.sums.name}</td>
          {data.sums.cells.map((cell, i) => (
            <td key={i} style={h}>
              {cell}
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
};

const initData = {
  metrics: {
    name: "Fields",
    cells: [],
  },
  rows: [],
  sums: {
    name: "Total",
    cells: [],
  },
};

const App = () => {
  const [metricNames, setMetricNames] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);
  const [data, setData] = useState(initData);

  useEffect(() => {
    const uniqueMetrics = getUniqueMetrics();
    setMetricNames(uniqueMetrics);

    const allFields = getFieldNames();
    setFieldNames(allFields);
  }, []);

  useEffect(() => {
    if (metricNames.length && fieldNames.length) {
      const selectedFields = fieldNames
        .filter((e) => e.checked)
        .map((e) => e.en);
      const selectedMetrics = metricNames.filter((e) => e.checked);

      const rowsParams = getRowHeadersByFields(selectedFields);

      const preparedData = getDataByMetricsAndRowHeaders(
        selectedMetrics,
        rowsParams
      );
      setData(preparedData);
    }
  }, [metricNames, fieldNames]);

  const metricToggle = (metricName) => {
    const index = metricNames.findIndex((item) => item.ru === metricName);
    const changedMetric = {
      ...metricNames[index],
      checked: !metricNames[index].checked,
    };
    setMetricNames([
      ...metricNames.slice(0, index),
      changedMetric,
      ...metricNames.slice(index + 1),
    ]);
  };

  const metricMoveUp = (index) => {
    if (index > 0) {
      const reorderedMetrics = [
        ...metricNames.slice(0, index - 1),
        metricNames[index],
        metricNames[index - 1],
        ...metricNames.slice(index + 1),
      ];
      setMetricNames(reorderedMetrics);
    }
  };

  const metricMoveDown = (index) => {
    if (index < metricNames.length - 1) {
      const reorderedMetrics = [
        ...metricNames.slice(0, index),
        metricNames[index + 1],
        metricNames[index],
        ...metricNames.slice(index + 2),
      ];
      setMetricNames(reorderedMetrics);
    }
  };

  const fieldToggle = (fieldName) => {
    const index = fieldNames.findIndex((item) => item.ru === fieldName);
    const changedField = {
      ...fieldNames[index],
      checked: !fieldNames[index].checked,
    };
    setFieldNames([
      ...fieldNames.slice(0, index),
      changedField,
      ...fieldNames.slice(index + 1),
    ]);
  };

  const fieldMoveUp = (index) => {
    if (index > 0) {
      const reorderedFields = [
        ...fieldNames.slice(0, index - 1),
        fieldNames[index],
        fieldNames[index - 1],
        ...fieldNames.slice(index + 1),
      ];
      setFieldNames(reorderedFields);
    }
  };

  const fieldMoveDown = (index) => {
    if (index < fieldNames.length - 1) {
      const reorderedFields = [
        ...fieldNames.slice(0, index),
        fieldNames[index + 1],
        fieldNames[index],
        ...fieldNames.slice(index + 2),
      ];
      setFieldNames(reorderedFields);
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Pivot table nightmare</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <h3 style={{ textAlign: "center" }}>Columns</h3>
          <ul
            style={{
              maxWidth: "300px",
              margin: "10px",
              border: "1px solid gray",
              listStyleType: "none",
              padding: "10px",
            }}
          >
            {metricNames.map((item, index) => {
              return (
                <li key={item.ru}>
                  <button
                    onClick={() => metricMoveUp(index)}
                    disabled={index === 0}
                  >
                    ↑
                  </button>
                  <button
                    onClick={() => metricMoveDown(index)}
                    disabled={index >= metricNames.length - 1}
                  >
                    ↓
                  </button>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => metricToggle(item.ru)}
                  />
                  {item.ru}
                </li>
              );
            })}
          </ul>
          <h3 style={{ textAlign: "center" }}>Rows</h3>
          <ul
            style={{
              maxWidth: "300px",
              margin: "10px",
              border: "1px solid gray",
              listStyleType: "none",
              padding: "10px",
            }}
          >
            {fieldNames.map((item, index) => {
              return (
                <li key={item.ru}>
                  <button
                    onClick={() => fieldMoveUp(index)}
                    disabled={index === 0}
                  >
                    ↑
                  </button>
                  <button
                    onClick={() => fieldMoveDown(index)}
                    disabled={index >= fieldNames.length - 1}
                  >
                    ↓
                  </button>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => fieldToggle(item.ru)}
                  />
                  {item.ru}
                </li>
              );
            })}
          </ul>
        </div>

        <div>
          <PivotTable
            data={data}
            selectedFields={fieldNames.filter((e) => e.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
