export const dataset = [
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4870
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 899
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1360
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 5520
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1301
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 24459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 8742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 11115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17312
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1505
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2726
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 931
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1913
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 494
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 245
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 64029
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 9800
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2219
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 16102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1051
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 738
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4892
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4566
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 13818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 249
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3016
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3835
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2723
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 633
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 27498
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2589
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 401
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3934
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 436
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 439
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 440
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 10734
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 560
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 168
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2585
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 22891
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 7807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 13715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 18688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 6417
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 22443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4067
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 7413
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1532
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 4007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2940
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 13819
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 361
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 25133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3977
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 23063
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3784
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 16268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 260
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4419
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 562
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4671
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 21135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 870
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 21847
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1000
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6772
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6772
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 920
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1095
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 12407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2037
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 56691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 440
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 681
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 866
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 482
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 5295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4419
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 758
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 478
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1050
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 709
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 905
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 47008
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 15437
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 47044
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 266
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5088
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 682
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 26898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1644
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 601
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5662
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2753
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 15204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 22497
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 23594
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2004
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 213
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2876
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3680
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1051
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 15314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4570
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 17457
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 24614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 1",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4399
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 980
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 6501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13894
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 832
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5099
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 874
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 25342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 301
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 13187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4484
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8224
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 39474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 31376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1026
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3672
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 658
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 537
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 650
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 19914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2875
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 4143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 12914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4907
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 536
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1040
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31843
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 867
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 10855
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1520
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 671
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8725
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 5992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9559
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 30761
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2312
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 480
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 12726
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7269
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 45167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 346
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 12173
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 347
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 728
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 310
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 812
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 8649
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9648
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13695
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2097
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1973
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 290
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6620
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 569
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1001
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 450
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1070
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 510
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 29118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 13130
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 531
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2490
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 51644
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1597
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 4143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 700
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 585
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 990
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 183
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3861
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6811
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1825
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4811
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 173
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 9",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17899
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 17717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 596
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 791
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 553
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 495
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 893
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1758
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 456
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2578
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12301
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 273
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1820
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 8569
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 418
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 560
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 23016
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 21365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 35503
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 553
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 38198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 7482
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18436
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4624
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 10279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 746
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 928
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3832
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 239106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "Product offer 6",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27090
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 690
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1061
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 285
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 203
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1081
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 14056
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 439
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 6951
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 977
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 249
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 17892
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 30742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 777
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 10769
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 710
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 300
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 34860
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 896
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4071
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 25175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9883
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 28125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2677
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 252
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2757
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6861
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6756
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 44978
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4774
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 656
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1012
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 17831
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 45455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 834
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 18364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1497
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3058
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 5337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 730
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 572
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 39039
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 746
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 18011
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1034
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 867
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 21707
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 653
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 911
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3530
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2630
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1332
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 930
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2399
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 398
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1810
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 709
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 9331
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 16540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 22321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5624
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 14414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2938
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 6771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2828
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5805
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4814
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2095
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 624
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1054
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 14759
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3859
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1608
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 361
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 891
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 5355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1034
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 82018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6839
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 648
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 915
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 332
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "Product offer 28",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6468
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4964
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 497
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 5267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2374
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 32571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 33166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 649
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2399
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 285
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5888
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 518
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 24437
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1773
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 10159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 445
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2311
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9331
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1482
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2350
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 934
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 522
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 510
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 9698
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1752
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 120
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 390
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 8010
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1244
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4687
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3591
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 11089
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 5945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7976
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 178152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 869
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 100469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 316
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6982
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 428
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 610
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1665
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1665
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 23301
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 20251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 887
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 28221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 445
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 11299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1743
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 79117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 8371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23095
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 12221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 23591
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2662
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 252
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 40629
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 610
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 801
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1830
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 10556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 820
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4021
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 389
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1770
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 361
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 400
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 888
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 23253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 3345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 20304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2709
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 120
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3074
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 542
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1063
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 27817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 273
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 608
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 372
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 930
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2396
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4505
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 987
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1410
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64029
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9324
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1070
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 130
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37099
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37099
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 266
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1338
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 22348
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4553
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26057
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 283
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 16692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 529
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 200
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 559
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 563
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1168
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4374
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 440
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 471
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 183
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 396
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1051
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 8792
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 8792
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13434
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 580
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4484
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3809
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4071
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 260
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 61788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2452724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 209
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 411
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 425
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1359
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8401
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 533
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 16020
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16486
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24642
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 698
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4603
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 761
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1410
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3860
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 853
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 31864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 120501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 120501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1382
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6533
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2240
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2240
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9019
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 259318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1011
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1011
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7550
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 598
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1025
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1562
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 835
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 835
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 1",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7028
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 14280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4810
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 890
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 890
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 8574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 678
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 678
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1686
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 19767
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4706
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2525
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3460
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 8160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25796
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8006
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 627
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6750
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 627
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6750
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 62221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 962
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 224
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1814
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 3",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6003
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 983
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 983
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3925
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6311
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 3",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1002
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 31225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2893
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7485
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 244
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 8106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1639
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 18613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20332
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13920
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18660
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 352
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4723
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 4143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1503
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16844
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 674
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 674
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1417
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2566
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 66685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 25117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 374716
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 21551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 486
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7680
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 398
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 311
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4219
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5633
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 607
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3526
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1930
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1930
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 339
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 339
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 47044
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1687
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1417
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1784
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 556244
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 44028
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 12741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 665
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5853
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 346
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1507
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 30381
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 560
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 672
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3005
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 400
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2425
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24530
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4047
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3499
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 695
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1417
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 278
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7833
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1531
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1531
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 450
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14360
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17734
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 35325
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 11297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13695
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 7823
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 41590
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2809
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13605
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1754
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1754
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 502
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 573
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 931
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 439
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 384
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 303703
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 303703
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6580
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14750
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20696
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20696
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1470
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 396
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 357
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 357
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 584
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2585
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22891
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 840
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 260
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7009
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 27997
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 476
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 21594
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 861
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18984
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 23420
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 506
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2357
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 9600
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 437
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 437
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1400
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 11170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 260
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 53255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1786
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 21382
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 568
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 7108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1470
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1470
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 7326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 350
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 858
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 61018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 61018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1090
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 876
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 30742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 39324
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 224223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 123807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 33611
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5670
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 39295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 22277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 955
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 8006
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 3371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 694
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 498
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 10013
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 7643
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 10365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4965
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 597
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 597
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 559
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 559
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 132299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 527
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 457
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 468
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 725
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 191
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 369096
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 310
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 839
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 310
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 839
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3594
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 832
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1976
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 357
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 23016
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18956
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 468
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3465
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 37951
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1045
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 23594
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 587
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1040
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 7032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 505
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 48549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5057
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 986
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1672
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 16020
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3536
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 934
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25567
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 17872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67285
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 36363
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 4240
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 894
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3996
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6656
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16346
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 580
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 20841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 62597
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1458
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "Product offer 5",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 656
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 101983
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "Product offer 6",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 506
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1629
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1926
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 932
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 457
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3887
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2396
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 893
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1951
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1805
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2537
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1520
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24642
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 28221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 17271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3572
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3572
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 594
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3590
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16006
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 779
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 416
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2049
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1558
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 9948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3973
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 4477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 676
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 440
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14546
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1080
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 947
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7760
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 21366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 580
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1680
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3570
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1062
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4078
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4078
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 288
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1608
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2596
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 5853
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 943
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 13187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 790
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 750
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1565
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 324
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1497
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2808
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15853
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 536
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 495
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 870
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 870
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6911
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 39886
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "Product offer 28",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1002
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5057
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1418
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 853
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 5762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 439
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 316
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 52770
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 506
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4027
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 200
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 5392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 591
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 215
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3702
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 10370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9560
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 840
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 840
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1780
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 8087
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 9137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 413
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 530
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39324
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 69321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 347
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 347
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1416
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9213
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 917
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9213
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 917
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1034
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38542
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38542
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 965
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2951
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 203
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 510
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 354
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 504
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 10156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 940
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 656
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 527
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 527
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1120
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 20002
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 12740
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2410
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 981
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 15165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 485
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 753
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 22139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4047
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4047
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 295910
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 86132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2585
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 631
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1390
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 11492
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 6082
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4094
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7523
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 45310
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 5166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1773
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3026
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 338
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1773
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1865
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 801
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 729
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 694
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 490
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 2330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2589
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 11502
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 654
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 979
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4907
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 641
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 641
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2593
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 465
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 624
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 4198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15659
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 120
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 515
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2608
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8738
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 2105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9676
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9676
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 303554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 303554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39039
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2001
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21747
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 307
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1754
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 3921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 13161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 33166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 580
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11456
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11456
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 8975
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12173
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12173
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 249
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3054
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 1178
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 814
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 18243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6082
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8493
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 716
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 436
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 6259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 48549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16006
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8371
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 702
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3996
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 3929
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 660
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 61788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 61788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 28405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3572
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 374
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 2125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 350
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 300
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 325
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 11033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 833
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 562
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 498
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Presentation count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 290
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 3079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 690
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 3831
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 993
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 13394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 15788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 1493
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 39295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4078
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2721
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 26616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2936
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 660
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 168719
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 660
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1275
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 1008
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2061
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 478
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 400
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 7881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 2095
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 3983
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 643
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 656
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11010
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 9601
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 6836
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 930
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4051
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 460
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 460
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 544
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 13895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 61991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 7211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20332
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 3885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 602
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 1085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3002
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21880
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1801
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 5379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3547
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18569
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Presentation count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 100469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 29118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Presentation count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 21688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 50154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 9290
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 7116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 357
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Presentation count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Presentation count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Presentation count",
    "value": 2806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 8426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6998
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 6998
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 390
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22891
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 22891
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 2616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Presentation count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 2097
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 5487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Presentation count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Presentation count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Presentation count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 6982
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Presentation count",
    "value": 33988
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Presentation count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 16586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Presentation count",
    "value": 2616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Presentation count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Presentation count",
    "value": 983
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Presentation count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Presentation count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Presentation count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1581
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 311
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3005
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 643
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 3",
    "product_offer": "Product offer 3",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 245
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 419
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 328
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1654
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1592
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 641
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 525
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1076
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 570
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 772
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1219
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 873
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1466
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 4577
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 776
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 209
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 389
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1558
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 250
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 2831
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 729
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 466
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1285
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 879
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 130
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 335
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 869
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1099
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 760
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 6381
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 7236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 278
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 753
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3596
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 547
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 735
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 200
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 210
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 564
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 488
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 160
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 213
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 746
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 310
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4694
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 530
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 290
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 124189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 450
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 311
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 609
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 460
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 249
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 870
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 347
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 8986
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 723
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 693
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2359
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 525
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4087
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 694
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 283
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1019
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 5082
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 273
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 786
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4886
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 184
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1495
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 272
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 713
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 300
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 155
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 290
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 283
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 732
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 418
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 736
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1450
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2635
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1000
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 402
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 764
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 372
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 370
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 449
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 9",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 3697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5069
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 132
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 312
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "Product offer 5",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 410
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 209
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 502
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 247
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1631
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 980
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 793
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 793
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 453
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 65
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 118450
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 623
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 190
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 1",
    "product_offer": "Product offer 1",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 8019
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 484
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 424
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 2676
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3867
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 168
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 401
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 849
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 401
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 849
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 251
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 865
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 338
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 597
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3041
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 786
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1183
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 473
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1076
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1482
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 270
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 270
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 84
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 66
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 120
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 262
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2466
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2466
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 452
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 486
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 520
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 970
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 340
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 866
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 96
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3867
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3867
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 170
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 183
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1735
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 417
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 97
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 457
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1362
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 813
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 693
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1313
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 413
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2466
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1088
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3461
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 271
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 425
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 425
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 42
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 578
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 813
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 984
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 842
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 213
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 360
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 360
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 634
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 747
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 288
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 312
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 265
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 265
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 721
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1482
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3754
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 150
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 269
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 280
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 265
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 109
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1219
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1628
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 390
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 390
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 876
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1590
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 842
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 500
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 399
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 347
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 130
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 879
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1628
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1628
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 813
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1016
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 490
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 980
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 245
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 287
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 540
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 86
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 248
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3029
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 220
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 85
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 640
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 8986
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 270
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 180
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 78
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 522
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 981
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 981
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 456
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 335
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 183
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 4048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 214
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 670
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 670
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 99
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 81
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 196
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 480
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 155
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 486
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 24
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 17228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 31
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 4323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 136
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 628
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 82
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 1",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 374
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 176
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 465
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 155
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 151
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 402
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 221
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 751
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1899
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 749
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 88
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 337
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 53
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 57
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6796
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 1",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 682
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7952
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 430
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 68
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 215
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 27
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 344
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 123
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 72
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 46
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 639
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 575
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 1292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1866
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 961
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 380
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 77
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3521
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 23
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 810
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 2848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 9",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 51
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 64
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 74
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 54
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 44
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 720
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 47
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 91
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 334
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 545
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 980
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 3804
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1874
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 1874
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 41
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 71
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 2074
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 320
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 37
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 79
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 448
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2680
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 418
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1020
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1020
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 563
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1240
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 94
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 335
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 530
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1812
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 168
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 243
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 363
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 2157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 5713
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 230
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 814
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 83
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 401
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 29592
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 893
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 567
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 34
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1641
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 35
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 400
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 11
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 67
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 163
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 38
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1330
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 21
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 48
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 536
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 110
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 254
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 2901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 6789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 43
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 140
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 98
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 62
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 73
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 12
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 994
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 61
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 58
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3638
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 16
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 63
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 56
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 39
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1590
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 1590
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1041
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Sale count",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 349
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 36
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Sale count",
    "value": 76
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 3
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Sale count",
    "value": 2
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 69
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 7
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 300
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Sale count",
    "value": 1
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Sale count",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Sale count",
    "value": 1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Sale count",
    "value": 33
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 87
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 59
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Sale count",
    "value": 6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Sale count",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Sale count",
    "value": 8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 81.9259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.4513
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 28.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 39.3443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.6868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100.5291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.5859
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 89.4737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 29.6296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 22.6164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 81.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 31.7073
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 35.3535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 45.4545
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.031
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 24.7104
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.5472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0.7599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 1.5625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 25.4237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.2721
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 66.8919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.4649
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.3967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 36.2319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 10.5645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.8954
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 35.9195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 46.6989
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.1791
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.8842
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 1.4706
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 36.1446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 17.4716
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 94.8718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.9663
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.1905
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 90.6404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.0303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 20.8333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 38.4615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 17.5824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 2.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 10.7843
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.9259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.6982
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 1.0638
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 9.6774
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 45.4701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.6049
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.3802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 23.0769
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.5145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 37.2881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.732
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 83.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 27.6471
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 10.633
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 46.1712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 10.9747
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 28.8684
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 6.4516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.4112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 37.8947
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 91.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 5.9626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 78.49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 107.0452
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 83.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.6353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 19.3622
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 5.6075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 62.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 95.9184
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 84.8485
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.5323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 91.3043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 32.9268
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 35.8974
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.0926
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 8.8742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 7.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.3281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.9252
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.9336
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.8223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 67.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0.5199
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 95.8937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 21.1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 15.7076
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 82.7586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 90.3226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 93.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 86.8852
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 83.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.0687
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 103.3172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 31.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 36.6972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.1189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.9718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 2.5424
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.3881
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.8054
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 97.9701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 53.7879
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.4075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 28.3582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.6103
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 2.3256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 21.1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.0833
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0.5217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 69.549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 2.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 57.0552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.2143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 59.7701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 14.7368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 85.1485
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 17.6781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.8343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0.5068
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 56.5217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 16.8675
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 56.9892
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0.4292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 4.9088
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 7.9393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.3708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 71.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 94.7826
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 28.4768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 69.5219
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 62.201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 32.0988
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5831
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 1.89
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.1303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 81.6901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 18.6047
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 5.042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.9591
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.8366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.1975
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0.2801
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 22.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 88.5636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.3388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.4443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 25.3027
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 91.8367
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 69.4539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9767
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 6.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 101.3624
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 40.619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.1113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22.5806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.1887
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 16.2791
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 4.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 53.0945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.5991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 21.3992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.3858
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.2258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 8.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2325
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.4725
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.9648
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 15.625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.1856
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 40.2299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 3.8403
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 96.8317
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 40.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 37.7528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 33.6898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.1916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.9797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 4.8387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.4004
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.8581
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 23.913
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.2174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.1765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 41.3793
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.2058
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 18.3673
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 51.8519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 29.5652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.5496
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 46.5517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 39.604
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.7242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.8065
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0.5927
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 28.4653
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 21.2389
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 69.5473
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 44.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.0606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 51.5152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 68.4211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 29.4118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 7.8431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 11.2555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 7.2678
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 89.1566
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 11.7593
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.4231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 61.5385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 19.963
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.9365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 58.4838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 6.3519
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 18.7097
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 75.6098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 93.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.9524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 83.6673
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.9373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 93.4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.3492
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 94.7368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.2188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.2904
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 74.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 24.3902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 57.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 38.945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8734
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 92.1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 41.1765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.8048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 5.7237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 96.5909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 12.0427
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0.1406
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.4376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 7.6186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 55.207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 43.1579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 69.4539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.6715
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 58.5366
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1034
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 31.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.7817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 41.791
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 24.7934
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.8516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.8516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 22.7815
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.5279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.6558
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 15.3509
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 35.6322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.7374
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.0187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.9339
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 1.2712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 45.3744
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.6385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.5617
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 3.2258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 40.293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0.4175
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.5708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 13.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 118.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1761
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.2967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.1454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 44.898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.6239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 88.2353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 47.8261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 16.129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 37.931
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.5919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.4483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.4664
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 40.1869
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 71.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 79.6825
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 57.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 96.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.9767
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 58.5859
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.1238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.9345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 1.8018
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 41.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 45.1613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.8966
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 41.3043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.2703
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9419
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.1717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 85.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 26.4415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 53.012
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.7661
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.7661
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 42.1053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.8507
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 2.487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 16.7582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.7688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.0738
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 21.6216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.8603
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5331
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.6117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.2079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.349
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 92.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 29.3785
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 11.4031
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 8.4222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 26.8293
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 68.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.2009
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 68.2836
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 77.1084
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.7242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 90.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 23.2558
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 38.1053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 31.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3761
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 87.7238
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.6164
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 51.8039
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 6.1858
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.4318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.0567
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.6595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.7838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 107.4555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 48.4848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 39.8119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 16.7906
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2009
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 51.8701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.1314
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.2962
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 67.4847
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 8.7719
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.1795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 92.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.8228
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.092
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 81.8182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.2297
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.2272
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 23.4925
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 23.0032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 49.4253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 93.2489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.7765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.4454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.4454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.0539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1494
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 18.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.5708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 97.5978
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 56.1404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.3158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 3.4139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100.2375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5335
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 56.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 9.3023
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.1724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 13.1579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.7308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.0643
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 29.8246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 18.5351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4688
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 31.8841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 70.5882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.6553
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.6868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 16.4179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.1291
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0.8929
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 3.2258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.4423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.3846
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.4516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 30.2326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 1.8423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 13.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 24.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 23.1649
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 133.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 8.6957
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 49.562
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 44.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9979
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.2202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 7.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 39.8058
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.7541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 8.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.3077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 68.9655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.5185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 56.391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.9383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.0534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 94.3182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 6.0606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7843
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 96.4497
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.8365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.627
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 64.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.6794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.102
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.0288
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 84.2031
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.3095
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 68.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 56.044
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.809
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 78.7234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 8.0131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 27.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.6185
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.8036
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 12.7451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 40.613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 15.6997
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.3386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.7436
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 5.4348
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.2632
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.8119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 1.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 22.4513
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 44.2748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 7.9611
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4969
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.5634
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.0674
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 64.1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 103.9216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 83.2404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 25.974
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 1.5385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.5764
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 2.2222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 14.7541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 55.4348
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.2748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 26.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 93.617
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 13.7681
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 101.6722
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 53.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 74.8861
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 15.1515
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 103.3654
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.3636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.3223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20.0375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.3299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 31.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 23.5294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 93.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 33.9623
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6906
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 97.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 41.8685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 26.1477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.6898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.6898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.1141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.0118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 38.2637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 99.0991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 6.6869
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.9483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 19.375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 21.7494
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 47.8827
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 2.451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 82.5397
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.155
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2289
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 87.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 8.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.8939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30.1724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 35.1852
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 14.5038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6173
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 46.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 46.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "Product offer 4",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 68.6729
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 101.2999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 101.2999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.5048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 43.6957
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 3.0303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.6386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 47.619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 1.8599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.2735
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 70.5882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1146
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.9446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.3938
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 63.0233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.8273
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3315
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.3135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 10.3714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.0698
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.1364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.5038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 63.6166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 86.6212
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 64.8148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.8379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.5441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 29.646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.0825
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 18.3673
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.0937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.9447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.3259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 39.5349
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 200
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.1859
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.3129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 24.7304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 80.9524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 55.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.5329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 43.6416
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.8168
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 72.2772
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9224
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.7575
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 91.1765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.2342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 58.8457
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 64.2623
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 42.5697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 19.1029
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 87.7119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 36.1702
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 26.087
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.1465
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.9885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 38.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22.3776
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.6007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.7117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 14.1304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1494
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 98.5075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.6452
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 92.916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.4319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3605
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.6364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.6364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.8085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 62.7907
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.4784
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9716
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 22.8916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9786
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2854
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 67.3077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 75.2577
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 63.4409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 2.9412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.152
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.7895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.5259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 95.4545
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.0745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.0685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 9.7267
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.686
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.774
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.4448
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 93.0384
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 1.7544
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.7972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 47.3684
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 49.2063
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.3899
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 13.8953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.4433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 1.9663
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.3024
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 52
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 42.623
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 86.9131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 36.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 2.8169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 56.9209
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.6742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 3.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6008
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.5908
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2384
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "Product offer 5",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 75.7576
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.759
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 18.4615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.1189
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.944
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 75.3138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 96.5517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 61.7647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 34.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 98.2759
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.2157
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 5.364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 96.3785
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.4534
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5811
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.0375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 36.8502
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 16.094
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 20.7547
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.2241
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.1007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.8235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 41.0377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 3.6364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 71.875
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 17.3302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 35.3566
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 22.9508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 54.5024
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.5263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 53.4884
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 9.0498
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.5319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8558
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 27.8599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.8259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 46.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 50.5263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 9.5628
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.4206
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4309
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.4415
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5568
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 4.1527
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 18.705
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.5806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 94.708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.8841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.9245
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.7451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.295
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 37.7953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 61.6876
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 86.9637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 45.098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.2862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.6246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 6.6942
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 35.0877
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.0668
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.8302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.2882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 4.4259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 49.554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.971
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.5171
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.3281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.6379
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.6117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.8901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.8321
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.2555
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 60.625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 84.6154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4975
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 3.8961
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.5259
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 29.1411
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.5468
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 58.9704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.9113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 23.0769
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 43.6652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 102.1645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 48.9209
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 6.6116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.9106
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 61.9048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 68.8172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.7237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 30
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 51.2195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 101.5848
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 54.0741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 38.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 55.5477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 81.768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 84.0149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 13.1443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 25.6684
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.1618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.2552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 81.4815
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 45.122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 70.6767
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.2361
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 18.3246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.6142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.5812
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.6408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.9838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 85.9375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.2941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0078
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 28.6957
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.3007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1905
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 45.8015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.5477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.5477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.0894
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 26.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 96.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 13.4752
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.4
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.2637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 92.3423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 50.9804
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 40.6593
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 38.5246
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.8051
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.2187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 94.3005
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 38.7097
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 43.7364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.1571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 71.0526
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 4.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.439
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 94.8454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 57.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.0634
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4728
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.2375
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 81.0127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 8.0187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.7032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 61.2903
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 81.3896
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4192
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 54.4554
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9012
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 40.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.8961
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 83.4343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.3153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.4601
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 5.1064
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 51.6129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.1791
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 1.2987
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.5724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.1792
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 83.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 102.1944
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.5573
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 107.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0.7407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8669
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.6774
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 86.2069
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3147
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3698
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 97.3451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5694
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 81.5348
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 94.8718
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.3226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0.7752
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 93.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 84.153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 20.7317
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 30.7692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 92.3581
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.4142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 21.5359
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.1182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.3112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 28.4722
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.0604
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.2059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 5.4878
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 55
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 4.8193
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 56.9948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.6053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 35.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.5775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.1916
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 62.4074
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.3895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.6741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 6.2201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 64.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.2804
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 97.9042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75.3138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.4341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 93.2292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0657
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 107.17
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5673
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.2436
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 62.8485
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.1408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.1599
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.9418
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.402
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.1475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 51.0345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.7021
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 38.8929
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.3478
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 103.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.9501
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4423
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 74.6356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 31.7343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 23.0769
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 23.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 38.4615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 89.6194
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.4046
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 44.5736
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 46.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.3788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.5652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 81.6901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 34.5048
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.1475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 93.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.6926
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.8351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 15.7895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 64.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.4426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 12.9286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 29.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.2535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 13.7931
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 49.7207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.3743
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.4513
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.3743
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.9239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.8318
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.9921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.9574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.2064
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.2064
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2124
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.2897
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 62.1186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 65.8257
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 45.0725
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 12.8205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 39.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 26.4925
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 22.6131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 6.3112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.6131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 55.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.5712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.5712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 54.717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 77.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.0523
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 29.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.0408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.1227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.0408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.1227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.13
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 55.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.8502
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 58.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.2637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.2989
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.5472
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 8.1197
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 101.3699
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3986
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 15.3846
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.0284
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 31.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 39.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 62.2837
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 23.1595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.0827
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.2064
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.0576
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 73.2143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.0576
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 73.2143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.4516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.9125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 94.7387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.0884
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9854
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 1.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.0645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.596
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 33.452
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2507
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 77.6119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.1204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.1204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.4615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 45.1056
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 90.0131
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.4483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.7717
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.6868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.9801
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.7066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 47.619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.1477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.3636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.2549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.7902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 93.4708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 52.809
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.3902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.5862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.0451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.6802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0.1495
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 25.0919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0.1495
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 25.0919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0.4651
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 69.6629
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8679
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.9181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 81.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 15.3846
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.0612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.8516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 47.8827
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 17.6471
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.7722
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 51.7045
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 61.8298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20.5195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.2324
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.6652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 84.4828
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 56.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 74.6988
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 27.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.4376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 45.1705
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 92.9938
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 31.1475
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 59.4872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 51.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 51.8701
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.6957
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2325
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.4433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.4433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.4731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4292
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 84.5582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.3535
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.9368
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0.277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 51.0274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.7042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 58.1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.3458
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 84.5697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 62.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 62.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 11.5646
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.3023
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.8462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 21.9098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7633
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6949
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 77.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 62.2837
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.8929
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.2762
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.1011
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 73.2143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 14.9606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.5477
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0.8547
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 6",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 97.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.8077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 19.7802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.1551
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5068
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.9459
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.2663
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 35.2941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 35.2941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.4174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.1071
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 65.9746
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.0284
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.4935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 25.4682
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 104.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9767
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 74.6356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 13.1068
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 58.8235
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.7343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.7343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 81.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.9431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.0038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.1065
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 41.5888
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 91.8699
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.181
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 30.137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.2161
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1691
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 21.8579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9892
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 2.9126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.3077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.3023
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.3023
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 34.6939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 98.3553
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.4528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.4595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.2356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.2174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.2174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 47.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.282
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2894
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.7907
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 33.2148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 34.4454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 110.7422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.5862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 8.349
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 84.1584
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.3543
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 89.5753
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 61.9697
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.808
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.5342
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.3902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.8889
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.9973
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 74.2515
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 54.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.8255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.8376
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 39.0144
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.7613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.6451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 23.1595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 36.8421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2062
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.5591
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.3226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 54.8387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.7868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 22.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.9383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.2683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 9.2172
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.325
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.8815
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 9",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.7156
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.2121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 4.8066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 71.223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.9707
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.1816
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0836
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.1768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0.1012
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.0476
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.3043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.3043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30.7692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4134
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 103.1348
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6949
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0695
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.0541
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 79.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.8802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 80.303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.5356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.2345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.5335
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.5263
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 6.3112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1464
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1464
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.9388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.3735
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.1765
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 9.7561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.8462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0723
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 22.4299
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 28.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 75.2033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 87.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 16.9118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.6949
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.0803
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9662
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.1281
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 104.8807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 8.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.5215
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6893
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.6804
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.5222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.5328
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.5328
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.8409
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.0412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 86.9198
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.8205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.8205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.2994
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5755
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 67.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 86.0377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 68.9873
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 27.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.0612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.2012
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.652
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 92
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.0879
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 13.4454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.3067
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0982
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 40.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3616
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3822
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.7841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.9633
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.2464
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.7032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7544
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7544
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.9108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.8687
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 46.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 15.625
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 76.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.2093
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.7712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3398
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.6704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 64.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 33.0508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 60.8441
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2341
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.3444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 33.4884
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.7093
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.3935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 49.2647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.1462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.1169
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 98.0769
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 71.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.5882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 38.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.07
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.4657
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.6154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.1454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 71.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.8426
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 82.7465
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5525
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 10.9756
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 52.381
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 7.5306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 31.1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.4118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.5644
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "Product offer 28",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 16.3265
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 70
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 55.8442
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 67.4847
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 23.5294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 83.0986
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 47.0883
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 4.7771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 4.7771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.5309
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 26.3158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.0188
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.8959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.8959
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.6898
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 20",
    "product_offer": "Product offer 28",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 60.1231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 16.568
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 60.1231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 16.568
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 16.9811
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 62.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.1977
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 13.2405
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 27.4637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.0725
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 112.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 25.1462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1761
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 83.6066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 83.6066
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.19
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.5785
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 109.4737
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.6229
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 34.2105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.9545
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.2586
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.2218
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 35",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.2571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.1955
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 35.9862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 35.9862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 52.381
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 101.5764
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.8507
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 96.8317
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.0088
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 92.2184
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 24.7788
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 70.5882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.1304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.5598
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.7619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 54.5614
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.4096
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.4096
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.2373
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.6186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6906
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 3",
    "product_offer": "Product offer 3",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8696
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.642
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8696
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.642
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.7345
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 45.122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.2395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.2395
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 51.2195
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.402
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.4075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.5873
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 1.8391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 32
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 16.5394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 16.5394
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "Product offer 2",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.7868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.1079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 38.2353
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 68.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 108.0808
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 92.0635
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 12.8205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.7925
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 68.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 45.1613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.1469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 63.0233
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 65.0794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.4043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.0451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.0645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 72.7273
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 29.6424
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 12.3077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.2609
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.1443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.0303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.8966
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 90.3226
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.4634
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.4634
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "(Empty)",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.8575
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 47.619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.1689
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.1301
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.8987
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5914
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 8.1224
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2632
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.6863
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2632
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.6863
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.4873
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 22.9167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.8565
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 80.1948
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 38.2114
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.5921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.0377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.5921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.0377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8413
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8413
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 15.0242
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 12.3364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 77.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0793
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 54.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0793
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 54.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.0322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.8023
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 19.2308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 30.2326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.8685
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1775
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 17.3913
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2897
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 96.595
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 36.2745
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5835
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 58.642
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 88.7097
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.6999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 1.3158
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.9327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.661
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.4167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 3.4043
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 48.4561
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.5663
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 13.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.7637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 9.7902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 70.2703
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.3241
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 90.9598
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.5953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.9231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9115
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.005
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.2941
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 94.1294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 94.1294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 13.1443
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 34.0782
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.4015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.4015
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2451
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 86.8421
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 29.2683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 16.092
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.9873
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 4",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.4839
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.8648
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.4839
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.8648
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 88.8889
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 48.0712
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0.2331
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 53.7826
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 53.7826
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 43.6957
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 5.1892
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 55.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.0952
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 28.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1792
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 92.7203
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 98.7593
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5641
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1792
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 86.4583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.9009
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 53.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 9.8039
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 37.234
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22.8814
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.784
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.6517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 10.1476
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.0486
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 34.4828
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 19.1489
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 24.5283
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1711
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.5789
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 21.875
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 80.9524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.0515
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 57.732
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5205
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 48.1731
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 89.9716
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2729
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 1.0695
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.3125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.9237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.5708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.9237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.7619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 5.8527
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 9.6474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6776
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.1924
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.1924
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.4953
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 38.6026
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 38.6026
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22.3776
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 78.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 11.7647
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.1739
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.4637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 7.9932
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.2064
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.9606
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 90.2778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.1261
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 64.1509
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.107
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.0162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.7089
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.7089
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30.4813
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 12",
    "product_offer": "Product offer 13",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 85.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.438
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 7.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 10.1227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 10.3704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.0383
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 88.6126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2572
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 86.6044
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.7619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6116
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 69.9913
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 22",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 64.0884
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.1692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.1692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 54.7578
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.7528
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.6047
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.9121
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 22.9885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.1998
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 76.9231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.8696
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 46.1538
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 44.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 43.5216
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.8112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.8027
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.255
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 92.2184
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8056
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 14.7031
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 76.4706
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 12.5364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20.9524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.4676
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 47.2081
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.4896
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.2771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.2771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.9992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.828
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.3622
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0208
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0657
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.1516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.1516
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3698
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.1693
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.9938
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 73.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 50.6435
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 22.276
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.5518
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 32.1618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2894
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.7433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 68.8889
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.7333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 61.8298
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75.3138
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 20.864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 4.3165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 4.3165
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 90.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 87.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 20.5882
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.0038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.7817
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 89.8356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.5526
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0.5025
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0.5025
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 16.9118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 61.5385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 2.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 7.2626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.5795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.5795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3838
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9854
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 57.971
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.339
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2079
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0142
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.469
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.8063
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.4085
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.7304
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.5491
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.1053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 81.6901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 32.9082
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 46.5909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 29.4118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 35.6422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.829
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.6812
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.6768
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 38.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 49.467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 18.797
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 61.5385
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.0162
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1962
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 23",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.8431
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.1352
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.0937
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.1724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.875
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.4864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "(Empty)",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.2487
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9979
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.5748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.9388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 45.2454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.4662
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.2454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.4294
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 47.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.9992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.8531
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 7.994
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3067
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3067
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8391
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 64.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 64.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.9447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.433
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 4.8802
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 21.5517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 71.1864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 98.579
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.9009
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.3871
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 14.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.2684
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 89.3617
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.1113
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 2.9412
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0078
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 102.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.5355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3007
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.3306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.8056
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.6408
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.4617
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 57.0621
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 68.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 8.6207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.1702
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.1702
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.1053
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 85.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 83.0601
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 6.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.1277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7612
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.2032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.2174
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 69.5473
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 94.7826
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.7619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.6924
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.7719
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 65.0794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 17.7215
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 17.7215
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 81.2061
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 68.1159
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.2098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30.7692
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.0508
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.2101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 10.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9434
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.7447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.8901
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5658
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.0909
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 61.4203
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 28.7671
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.2483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.0968
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.5991
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 29.6117
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.0799
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 66.8919
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 19.3548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "Product offer 51",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.0187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.0187
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.3743
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 84.1081
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 37.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 54.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 94.8448
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 36",
    "region": "Region 50",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.7821
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.7619
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.9588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 107.2993
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 85.3211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 5",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.2549
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.4637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.1955
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.4637
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.1955
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6906
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 70.3704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 35.0726
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 84.4327
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 40.8
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.5603
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5747
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 3.8961
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 117.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.3704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.3704
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.329
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.4615
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 21.3382
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 4.4177
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 2.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.1277
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 95.4545
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.5862
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.6316
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 44.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.4872
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.2967
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 101.2999
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.3211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.9645
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 3.2258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.0945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.4653
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 63.3858
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 70.6522
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 19.2308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.3227
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.4653
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 5",
    "product_offer": "Product offer 5",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 38.3735
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.6038
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.322
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.6992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 48.7179
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 95.2191
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.2449
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.4118
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.6992
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2632
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2632
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0141
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.7225
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 10
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.8868
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 70.2479
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 99.2032
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.6582
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 27.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "Product offer 16",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 53.9759
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 36",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 96.2105
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.8302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.0833
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 10.0137
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 12.5749
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.402
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.49
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.6721
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.3924
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.3398
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 25",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 91.874
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 105.2786
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 54.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.7258
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 53.8462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.2683
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.6795
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.7613
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0.539
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 6.8027
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.6706
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 2",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 58.5526
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.8974
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 43.3931
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.3622
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.3622
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 87.2771
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.3223
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5217
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.5358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.9781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.5358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 58.9781
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3626
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.5719
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 8.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 60.9052
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.2524
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 40.5365
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.4231
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.4513
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7972
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.2139
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 67.101
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 32.3933
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.5149
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.9828
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.6923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.3846
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.4748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 24.2424
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 92.8571
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.1742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "Product offer 17",
    "metric": "Conversion",
    "value": 66.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 35.8108
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 87.7358
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.9578
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.8302
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 6.6387
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 47",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 8.8458
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 32.2404
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.8343
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 17.2807
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 82.3529
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 23.0763
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 2.7264
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 46.5517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 93
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 30.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.5574
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 35.0921
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 108.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 45
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 88.0952
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3356
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.9126
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 86.8182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.5128
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 4.5708
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 8.1232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0.6135
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.6323
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 14.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 3.8462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 65.0794
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 97.1445
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 90.9091
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 35.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 34.4454
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.3388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.7422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 68.9655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "Product offer 50",
    "metric": "Conversion",
    "value": 1.4388
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.2202
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0.9434
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8844
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.3019
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 38",
    "region": "Region 17",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 7.3167
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.7895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.7895
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.809
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 11.5741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3945
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 69.4915
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.6883
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 50",
    "region": "Region 27",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 82.4253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.0087
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 34",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.7681
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 40.9483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.3382
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 21.3382
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7407
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9.3511
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.686
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 52.5581
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 19.2308
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 44.4444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 51.8098
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 55.5556
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0998
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 97.1154
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 45.122
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 71.6312
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.8036
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 67.033
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.6252
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.6252
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 14.2857
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 48",
    "region": "Region 24",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.4127
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0204
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 6.9686
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 10.5392
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.18
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 42.3077
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 22.2222
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 42.9984
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 34",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 8.4112
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 3.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 30.2326
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.0211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.3253
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.8936
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 55.7143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 9
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0.1675
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.0668
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 77.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.0668
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 69",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 77.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.3467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 6.3467
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "Product offer 10",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.2414
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 68.5714
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 57",
    "region": "Region 36",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.129
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1711
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 61",
    "region": "Region 39",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 4.5455
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 45.3125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 34.6939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.3824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 87",
    "region": "Region 80",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.6742
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0.6952
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 3",
    "product_offer": "Product offer 3",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 3.5806
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "Product offer 32",
    "metric": "Conversion",
    "value": 1.6393
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 16.286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 41.0377
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.8182
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.5355
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.4069
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 77.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 5.6075
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 29",
    "region": "Region 76",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 15.1092
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 55",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 2.9723
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 52",
    "region": "Region 59",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.1462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 25.1462
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8824
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.1444
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 41.8224
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 86.4903
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 86.4903
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "Product offer 29",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 27.2727
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.95
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 46.1935
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 11",
    "product_offer": "Product offer 12",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 42",
    "region": "Region 20",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 98.9583
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.7618
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1481
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.7256
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 28.3186
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.7778
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 41",
    "metric": "Conversion",
    "value": 60
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "Product offer 30",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 13",
    "product_offer": "Product offer 14",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 21",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 89",
    "region": "Region 77",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.1239
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.9386
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3861
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 2.0537
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 83",
    "region": "Region 75",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.653
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 46",
    "region": "Region 23",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "Product offer 18",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5279
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3897
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 16",
    "region": "Region 9",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 50
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 5.1724
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.864
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 19.2483
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.5726
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.6446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 106.6446
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 75",
    "region": "Region 57",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 40",
    "region": "Region 51",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.4305
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 11.1111
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0.7042
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 93",
    "region": "Region 83",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.6997
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 15.6997
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 9.5636
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 15",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9274
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 41.492
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 41.492
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 31",
    "region": "Region 54",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9237
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 88",
    "region": "Region 81",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 63.6364
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 18.1818
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.0655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.3035
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 5.3035
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 66",
    "region": "Region 44",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 3.1605
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 24",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 85",
    "region": "Region 79",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 12.7119
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 63",
    "region": "Region 41",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 33.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 64.7059
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.8378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.6598
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 23",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.8378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 90",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 13.6598
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.5133
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "All",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 22",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 1.1847
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.166
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 54",
    "region": "Region 55",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 14.8148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 3",
    "region": "Region 45",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 19",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 6.6667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 39",
    "region": "Region 18",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 6",
    "region": "Region 3",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 34",
    "region": "Region 73",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.3306
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 12.5
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 1.2552
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 17",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 56",
    "region": "Region 33",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 59.2885
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 10.8808
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 18",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 16",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 3.125
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 14",
    "region": "Region 7",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 71.4286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 24",
    "region": "Region 10",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.5013
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 77.1429
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 80
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 43",
    "region": "Region 21",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 65",
    "region": "Region 43",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 28.0576
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 19",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 35",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.1667
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 84",
    "region": "Region 78",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 11",
    "region": "Region 2",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0.9584
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 15",
    "region": "Region 8",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 71",
    "region": "Region 64",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 84.8689
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 1.0286
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 26.1693
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 26.1693
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.9378
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 39",
    "metric": "Conversion",
    "value": 90
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.5145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 7.5145
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 2",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 65.0655
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 78.9474
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "Product offer 15",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.9447
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 27",
    "region": "Region 29",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 24.3902
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "Product offer 42",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "Product offer 40",
    "metric": "Conversion",
    "value": 93.3741
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.8201
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 1",
    "region": "Region 60",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 4.7841
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 72",
    "region": "Region 66",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "Product offer 49",
    "metric": "Conversion",
    "value": 17.7748
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "Product offer 43",
    "metric": "Conversion",
    "value": 40
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 22",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 27.6316
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 20.8955
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 59",
    "region": "Region 82",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 82",
    "region": "Region 74",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 2.7746
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 23.4272
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 26",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 81",
    "region": "Region 72",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 8",
    "product_offer": "Product offer 8",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 8.1981
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 30",
    "region": "Region 58",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 20",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "Region 56",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 35.1351
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 5.2288
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 78",
    "region": "Region 69",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 17",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 74",
    "region": "Region 31",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 17.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 38",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 25",
    "region": "Region 12",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 9",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 79",
    "region": "Region 70",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 80.303
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 9",
    "region": "Region 5",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 62",
    "region": "Region 40",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 68",
    "region": "Region 62",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 1.7422
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 44",
    "region": "Region 52",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 29.6296
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 41",
    "region": "Region 19",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 97.6148
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 7",
    "region": "Region 4",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 21",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 49",
    "region": "Region 25",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 31.5517
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 80",
    "region": "Region 71",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 25
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 7.0588
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 26",
    "region": "Region 26",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 10",
    "region": "Region 13",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.6211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 76",
    "region": "Region 67",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 89.6211
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 28",
    "region": "Region 35",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 38.9153
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 27.463
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 13",
    "product_offer": "Product offer 14",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "All",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 37",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "All",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 19",
    "product_offer": "Product offer 27",
    "metric": "Conversion",
    "value": 0.3236
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.1832
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "All",
    "region": "All",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 2.1832
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "Product offer 11",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0.8056
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "Product offer 7",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 86",
    "region": "Region 14",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 3",
    "gosb": "GOSB 91",
    "region": "Region 65",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 45",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 9",
    "gosb": "GOSB 8",
    "region": "Region 47",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 53",
    "region": "Region 30",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 75
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 10",
    "gosb": "GOSB 51",
    "region": "Region 28",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 11.4923
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 77",
    "region": "Region 68",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.1232
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 35",
    "region": "Region 15",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 36.2319
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 37",
    "region": "Region 16",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 26.8939
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 32",
    "region": "Region 49",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 27",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 24",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 6",
    "gosb": "GOSB 47",
    "region": "Region 53",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 32",
    "product_offer": "Product offer 46",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "Product offer 21",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 73",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 1",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 28",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 1",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 14",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 4",
    "region": "Region 46",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 10",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 33",
    "region": "Region 32",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 2",
    "gosb": "GOSB 64",
    "region": "Region 42",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 29",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 45",
    "region": "Region 22",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 26",
    "product_offer": "Product offer 34",
    "metric": "Conversion",
    "value": 58.6207
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 8",
    "gosb": "GOSB 12",
    "region": "Region 6",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "Product offer 48",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 58",
    "region": "Region 37",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "Product offer 31",
    "metric": "Conversion",
    "value": 11.0548
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 7",
    "gosb": "GOSB 60",
    "region": "Region 38",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 30",
    "product_offer": "Product offer 44",
    "metric": "Conversion",
    "value": 5.0847
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 4",
    "gosb": "GOSB 20",
    "region": "Region 11",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "Presentation system 4",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 11",
    "gosb": "GOSB 67",
    "region": "Region 61",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 4",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 23",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 5",
    "seller_id": "All",
    "presentation_system": "Presentation system 2",
    "sale_point": "All",
    "dash_product": "Product 25",
    "product_offer": "Product offer 33",
    "metric": "Conversion",
    "value": 0
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 92",
    "region": "(Empty)",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 2",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 2",
    "seller_id": "All",
    "presentation_system": "Presentation system 1",
    "sale_point": "All",
    "dash_product": "Product 7",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "Presentation system 3",
    "sale_point": "All",
    "dash_product": "Product 31",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 73.2143
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 3",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 1",
    "gosb": "GOSB 13",
    "region": "Region 48",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 3",
    "channel_type": "Channel type 1",
    "salling_role": "Sale role 6",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 18",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 8.3333
  },
  {
    "period_type": "Month",
    "report_dt": "30.06.2021",
    "tb": "TB 5",
    "gosb": "GOSB 70",
    "region": "Region 63",
    "localiti": "All",
    "type_settlemment_population": "All",
    "channel": "Sale channel 1",
    "channel_type": "Channel type 1",
    "salling_role": "All",
    "seller_id": "All",
    "presentation_system": "All",
    "sale_point": "All",
    "dash_product": "Product 33",
    "product_offer": "All",
    "metric": "Conversion",
    "value": 100
  }
];